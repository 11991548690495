@import "utilities";

/* Button sizes

s: height 36px
m: height 40px

**/

// mixin for fonts, spacing, layout changes
@mixin cab-button-xs {
  @include font-medium-12;

  padding: $spacing-2 $spacing-4;

  &:not(&.cab-button_only-icon) {
    &.cab-button_link {
      padding: $spacing-1 0;

      &:not(:disabled) {
        @include font-regular-10;
      }

      &:not(:disabled):hover,
      &.cab-button_pressed,
      &:active {
        @include font-medium-12;
      }
    }
  }

  &.cab-button_only-icon {
    &.cab-button_link {
      padding: $spacing-1;
      transform: scale(0.6);

      @media (hover: hover) {
        &:hover {
          transform: scale(0.8);
        }
      }

      &:active,
      &.cab-button_pressed {
        transform: scale(0.8);
      }
    }
  }
}

// mixin for fonts, spacing, layout changes
@mixin cab-button-s {
  @include font-medium-14;

  padding: $spacing-2 $spacing-4;

  &:not(&.cab-button_only-icon) {
    &.cab-button_link {
      padding: $spacing-2 0;

      &:not(:disabled) {
        @include font-regular-12;
      }

      &:not(:disabled):hover,
      &.cab-button_pressed,
      &:active {
        @include font-medium-14;
      }
    }
  }

  &.cab-button_only-icon {
    &.cab-button_link {
      padding: $spacing-1;
      transform: scale(0.8);

      @media (hover: hover) {
        &:hover {
          transform: scale(1);
        }
      }

      &:active,
      &.cab-button_pressed {
        transform: scale(1);
      }
    }
  }
}

// mixin for fonts, spacing, layout changes
@mixin cab-button-m {
  @include font-medium-16;

  padding: $spacing-2 $spacing-4;

  &:not(&.cab-button_only-icon) {
    &.cab-button_link {
      padding: $spacing-2 0;

      &:not(:disabled) {
        @include font-regular-14;
      }

      &:not(:disabled):hover,
      &.cab-button_pressed,
      &:active {
        @include font-medium-16;
      }
    }
  }

  &.cab-button_only-icon {
    &.cab-button_link {
      padding: $spacing-1;
      transform: scale(1);

      @media (hover: hover) {
        &:hover {
          transform: scale(1.25);
        }
      }

      &:active,
      &.cab-button_pressed {
        transform: scale(1.25);
      }
    }
  }
}

// mixin for fonts, spacing, layout changes
@mixin cab-button-cta {
  @include font-bold-42;

  padding: $spacing-5 $spacing-9;

  &:not(&.cab-button_only-icon) {
    &.cab-button_link {
      padding: $spacing-2 0;

      &:not(:disabled) {
        @include font-regular-14;
      }

      &:not(:disabled):hover,
      &.cab-button_pressed,
      &:active {
        @include font-medium-16;
      }
    }
  }

  &.cab-button_only-icon {
    &.cab-button_link {
      padding: $spacing-1;
      transform: scale(1);

      @media (hover: hover) {
        &:hover {
          transform: scale(1.25);
        }
      }

      &:active,
      &.cab-button_pressed {
        transform: scale(1.25);
      }
    }
  }
}

.cab-button {
  @include cab-button-s;

  display: flex;
  gap: $spacing-2;
  border-radius: $border-radius-7;
  align-items: center;
  text-transform: uppercase;
  font-feature-settings:
    "clig" off,
    "liga" off;

  &:disabled,
  &_disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &_full-width {
    width: 100%;
    justify-content: center;
  }

  &_xs {
    @include cab-button-xs;
  }

  &_m {
    @include cab-button-m;
  }

  &_cta {
    @include cab-button-cta;
  }

  &_primary {
    border: 1px solid transparent;
    background: var(--color-bg-button-primary);
    color: var(--color-button-primary-txt);
    position: relative;

    &:active,
    &.cab-button_pressed {
      color: var(--color-button-primary-txt-pressed);
      background: var(--color-bg-button-primary-pressed);
    }
  }

  &_primary:not(&_pressed, :active) {
    &:hover:not(:disabled) {
      background: var(--color-bg-button-primary-hover);
    }
  }

  &_secondary {
    border: 1px solid var(--color-button-secondary-border);
    background: var(--color-bg-button-secondary);
    color: var(--color-button-secondary-txt);
    position: relative;

    &:active,
    &.cab-button_pressed {
      border-color: transparent;
      color: var(--color-button-secondary-txt-pressed);
      background: var(--color-bg-button-secondary-pressed);
    }
  }

  &_secondary:not(&_pressed, :active) {
    &:hover:not(:disabled) {
      border-color: transparent;
      color: var(--color-button-secondary-txt-pressed);
      background: var(--color-bg-button-secondary-hover);
    }
  }

  &_tertiary {
    border: 1px solid var(--color-button-tertiary-border);
    background: var(--color-bg-button-tertiary);
    color: var(--color-button-tertiary-txt);
    position: relative;
    border-radius: $border-radius-7;

    &:active,
    &:hover,
    &.cab-button_pressed {
      border-color: transparent;
      color: var(--color-button-tertiary-txt-pressed);
      background: var(--color-bg-button-tertiary-pressed);
    }
  }

  &_tertiary:not(&_pressed, :active, :hover) {
    &:hover:not(:disabled) {
      border-color: transparent;
      color: var(--color-button-tertiary-txt-pressed);
      background: var(--color-bg-button-tertiary-hover);
    }
  }

  &_ghost {
    border: 1px solid var(--color-button-ghost-border);
    background: var(--color-bg-button-ghost);
    color: var(--color-button-ghost-txt);
    position: relative;

    &:active,
    &.cab-button_pressed {
      border-color: var(--color-button-ghost-border-pressed);
      color: var(--color-button-ghost-txt-pressed);
      background: var(--color-bg-button-ghost-pressed);
    }

    &.cab-button_active {
      border-color: var(--color-button-ghost-border-pressed);
      color: var(--color-button-ghost-txt-active);
      background: var(--color-bg-button-ghost-pressed);
    }
  }

  &_ghost:not(&_pressed, :active) {
    &:hover:not(:disabled) {
      border-color: var(--color-button-ghost-border-hover);
      color: var(--color-button-ghost-txt-pressed);
      background: var(--color-bg-button-ghost-hover);
    }
  }

  &_link {
    color: var(--color-button-link-txt);
    transition: font-size $transition-1;
    background: none; // TODO: Pavlo Rybenko pls check
    border: none; // TODO: Pavlo Rybenko pls check

    &:active,
    &.cab-button_pressed {
      color: var(--color-button-link-txt-pressed);
      text-decoration: none;
    }

    > span {
      text-decoration-line: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 4px;
    }
  }
}
