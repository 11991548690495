@import "typography";

$spacing-1: rem(4px);
$spacing-2: rem(8px);
$spacing-3: rem(12px);
$spacing-4: rem(16px);
$spacing-5: rem(24px);
$spacing-6: rem(32px);
$spacing-7: rem(40px);
$spacing-8: rem(48px);
$spacing-9: rem(64px);
$spacing-10: rem(128px);
$spacing-11: rem(256px);
$spacings: (
  s1: $spacing-1,
  s2: $spacing-2,
  s3: $spacing-3,
  s4: $spacing-4,
  s5: $spacing-5,
  s6: $spacing-6,
  s7: $spacing-7,
  s8: $spacing-8,
  s9: $spacing-9,
  s10: $spacing-10,
  s11: $spacing-11,
);

// -------------------------------------
// Mixin for paddings
//
// @mixin: padding($top, $right, $bottom, $left)
// -------------------------------------
// usage:
// @import '@cabinet/styles/spacing'
//
// @include padding(s1, 12px, s4)
//
// -------------------------------------
@mixin padding($top, $right: false, $bottom: false, $left: false) {
  @if $top {
    @if map-has-key($spacings, $top) {
      padding-top: map-get($spacings, $top);
    } @else {
      padding-top: rem($top);
    }
  }

  @if $right {
    @if map-has-key($spacings, $right) {
      padding-right: map-get($spacings, $right);
    } @else {
      padding-right: rem($right);
    }
  }

  @if $bottom {
    @if map-has-key($spacings, $bottom) {
      padding-bottom: map-get($spacings, $bottom);
    } @else {
      padding-bottom: rem($bottom);
    }
  }

  @if $left {
    @if map-has-key($spacings, $left) {
      padding-left: map-get($spacings, $left);
    } @else {
      padding-left: rem($left);
    }
  }
}
