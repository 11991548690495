@mixin theme {
  --color-0: #fff;
  --color-1: #000;
  --color-2: #090909;
  --color-3: #1a1a1a;
  --color-4: #333;
  --color-5: #f5f5f5;

  // General
  --color-brand-primary: #ff2400;
  --color-brand-secondary: #00cfa6;
  --color-state-danger: var(--color-brand-primary);

  // Static
  --white: var(--color-0);
  --black: var(--color-1);

  // General Texts
  --color-highlighted: var(--color-brand-secondary);

  // Backgrounds
  --color-bg-1: var(--color-1);

  // Body
  --color-bg-body: var(--color-1);
  --color-body: var(--color-0);

  // Fonts
  --color-font-1: var(--color-0);

  // Header Nav
  --color-link-secondary: var(--color-brand-secondary);
  --color-link-action-hover: var(--color-brand-primary);

  // Pop-ups
  --color-pop-up-overlay: rgb(13 16 22 / 70%);

  // Sidebar
  --color-sidebar-bg: var(--color-2);

  // Dialog
  --color-dialog-bg: rgba(13 16 22 / 58%);

  // Select
  --color-bg-list: #1f1f1f;
  --color-bg-list-selected: rgba(0 0 0 / 64%);

  // Accordion
  --color-bg-accordion: radial-gradient(circle at 100% 100%, var(--color-3) 0, var(--color-3) 24px, transparent 24px) 0% 0% / 25px 25px
      no-repeat,
    radial-gradient(circle at 0 100%, var(--color-3) 0, var(--color-3) 24px, transparent 24px) 100% 0% / 25px 25px no-repeat,
    radial-gradient(circle at 100% 0, var(--color-3) 0, var(--color-3) 24px, transparent 24px) 0% 100% / 25px 25px no-repeat,
    radial-gradient(circle at 0 0, var(--color-3) 0, var(--color-3) 24px, transparent 24px) 100% 100% / 25px 25px no-repeat,
    linear-gradient(var(--color-3), var(--color-3)) 50% 50% / calc(100% - 2px) calc(100% - 50px) no-repeat,
    linear-gradient(var(--color-3), var(--color-3)) 50% 50% / calc(100% - 50px) calc(100% - 2px) no-repeat,
    linear-gradient(270deg, transparent 0%, var(--white) 100%);
  --color-accordion-active-state: var(--color-brand-secondary);
  --color-accordion-before-border: linear-gradient(99deg, var(--color-brand-secondary) 0%, var(--white) 100%);

  // Buttons

  // Button - Primary
  --color-bg-button-primary: var(--color-brand-primary);
  --color-bg-button-primary-hover: linear-gradient(0deg, rgb(13 16 22 / 15%) 0%, rgb(13 16 22 / 15%) 100%), var(--color-brand-primary);
  --color-bg-button-primary-pressed: linear-gradient(0deg, rgb(13 16 22 / 20%) 0%, rgb(13 16 22 / 20%) 100%), var(--color-brand-primary);
  --color-button-primary-txt: var(--white);
  --color-button-primary-txt-pressed: var(--white);

  // Button - Secondary
  --color-bg-button-secondary: var(--color-brand-secondary);
  --color-bg-button-secondary-hover: var(--color-brand-secondary);
  --color-bg-button-secondary-pressed: linear-gradient(0deg, rgb(13 16 22 / 20%) 0%, rgb(13 16 22 / 20%) 100%), var(--color-brand-primary);
  --color-button-secondary-txt: var(--white);
  --color-button-secondary-txt-pressed: var(--white);
  --color-button-secondary-border: var(--white);

  // Button - Tertiary
  --color-bg-button-tertiary: var(--color-brand-secondary);
  --color-bg-button-tertiary-hover: var(--color-brand-secondary);
  --color-bg-button-tertiary-pressed: var(--color-brand-primary);
  --color-button-tertiary-txt: var(--white);
  --color-button-tertiary-txt-pressed: var(--white);
  --color-button-tertiary-border: var(--color-brand-secondary);

  // Button - Ghost
  --color-bg-button-ghost: transparent;
  --color-bg-button-ghost-hover: transparent;
  --color-bg-button-ghost-pressed: transparent;
  --color-button-ghost-txt: var(--white);
  --color-button-ghost-txt-pressed: var(--color-brand-primary);
  --color-button-ghost-txt-active: var(--color-brand-secondary);
  --color-button-ghost-border: var(--color-1);
  --color-button-ghost-border-hover: var(--color-1);
  --color-button-ghost-border-pressed: var(--color-1);

  // Button - Link
  --color-button-link-txt: var(--white);
  --color-button-link-txt-pressed: var(--white);

  // Providers
  --color-bg-provider-card: var(--color-3);

  // Start page
  --color-start-text-with-border: var(--color-1);
  --color-start-text-border: var(--white);
  --color-start-text: var(--white);
  --color-start-text-highlight: var(--color-brand-secondary);

  // Dialog
  --color-bg-dialog: var(--color-dialog-bg);
  --color-dialog-border: var(--white);
  --color-dialog-close-btn: var(--white);
  --color-dialog-title: var(--white);
  --color-dialog-body: var(--white);

  // Scrollbar
  --color-table-scrollbar-foreground: var(--color-brand-secondary);
  --color-table-scrollbar-background: var(--color-bg-2);
  --color-sidebar-scrollbar-foreground: var(--color-bg-4);
  --color-sidebar-scrollbar-background: var(--color-bg-2);

  // Controls

  // Controls - Input
  --color-input-txt-placeholder: var(--white);
  --color-input-txt-filled: var(--white);
  --color-input-txt-label: var(--white);
  --color-input-list-txt: var(--white);
  --color-input-border: var(--color-5);
  --color-input-border-error: var(--color-state-danger);
  --color-input-border-focus: var(--theme-color-6); // TODO: do we need it?
  --color-input-border-disabled: var(--theme-color-4); // TODO: do we need it?
  --color-input-hint-icon: var(--color-brand-secondary); // TODO: do we need it?

  // Controls - Error message
  --color-error-message: var(--color-state-danger);

  // Select
  --color-bg-input-list: var(--color-bg-list);
  --color-bg-input-list-selected: var(--color-bg-list-selected);
  --color-box-shadow-input-list: 0px 8px 24px -6px rgb(0 0 0 / 16%), 0px 0px 1px 0px rgb(0 0 0 / 40%);

  // Reviews
  --color-reviews-card-border: radial-gradient(circle at 100% 100%, var(--color-3) 0, var(--color-3) 24px, transparent 24px) 0% 0% / 25px
      25px no-repeat,
    radial-gradient(circle at 0 100%, var(--color-3) 0, var(--color-3) 24px, transparent 24px) 100% 0% / 25px 25px no-repeat,
    radial-gradient(circle at 100% 0, var(--color-3) 0, var(--color-3) 24px, transparent 24px) 0% 100% / 25px 25px no-repeat,
    radial-gradient(circle at 0 0, var(--color-3) 0, var(--color-3) 24px, transparent 24px) 100% 100% / 25px 25px no-repeat,
    linear-gradient(var(--color-3), var(--color-3)) 50% 50% / calc(100% - 2px) calc(100% - 50px) no-repeat,
    linear-gradient(var(--color-3), var(--color-3)) 50% 50% / calc(100% - 50px) calc(100% - 2px) no-repeat,
    linear-gradient(270deg, transparent 0%, var(--white) 100%);
  --color-reviews-card-title: var(--color-brand-secondary);
  --color-reviews-card-image-border: radial-gradient(farthest-corner at 40px 40px, var(--color-brand-secondary) 0%, var(--color-3) 100%);
}
