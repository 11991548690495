@import "utilities";

@mixin ng-select-focused($border-color: var(--color-input-border)) {
  &.ng-select-focused {
    &:not(.ng-select-opened) {
      > .ng-select-container {
        border-color: $border-color;
        box-shadow: none;
      }
    }
  }
}

.ng-select {
  @include font-regular-14;

  &.ng-select-opened {
    &.ng-select-right,
    &.ng-select-bottom,
    &.ng-select-left,
    &.ng-select-top {
      > .ng-select-container {
        border-radius: $border-radius-100;
        background: transparent;
      }
    }
  }

  @include ng-select-focused;

  &.ng-select-disabled {
    opacity: 0.4;

    > .ng-select-container {
      color: var(--color-input-border-disabled);
      background: transparent;
    }
  }

  &.invalid {
    .ng-select-container {
      border-color: var(--color-state-danger);

      .ng-value-container {
        .ng-placeholder {
          color: var(--color-state-danger);
        }
      }
    }

    @include ng-select-focused($border-color: var(--color-state-danger));
  }

  .ng-select-container {
    gap: $spacing-2;
    padding: $spacing-2;
    height: 100%;
    min-height: $select-height;
    background: transparent;
    border: 1px solid var(--color-input-border);
    border-radius: $border-radius-100;

    &:hover {
      box-shadow: none;
    }

    .ng-value-container {
      padding: 0;

      .ng-value {
        @include font-regular-14;

        background: transparent;
        color: var(--color-input-txt-filled);

        .ng-value-icon {
          &:hover {
            color: var(--color-state-danger);
            background-color: var(--color-input-txt-placeholder);
          }

          &.left,
          &.right {
            border-color: var(--color-input-border);
          }
        }
      }

      .ng-placeholder {
        @include font-regular-14;

        color: var(--color-input-txt-placeholder);
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: 100%;

      .ng-value-container {
        .ng-input {
          padding: 0 $spacing-8 0 $spacing-2;
          top: 50%;
          transform: translateY(-50%);

          > input {
            color: var(--color-input-txt-filled);
          }
        }
      }
    }
  }

  .ng-arrow-wrapper {
    padding-right: 0;
    width: auto;

    .ng-arrow {
      border-color: var(--color-input-txt-placeholder) transparent transparent;
    }

    &:hover {
      .ng-arrow {
        border-top-color: var(--color-input-txt-placeholder);
      }
    }
  }

  .ng-clear-wrapper {
    color: var(--color-input-txt-placeholder);
    width: auto;

    &:hover,
    &:focus {
      .ng-clear {
        color: var(--color-state-danger);
      }
    }
  }
}

.ng-dropdown-panel {
  @include font-regular-14;

  box-shadow: var(--color-box-shadow-input-list);
  border: none;
  border-radius: $border-radius-20;
  background: var(--color-bg-input-list);

  .ng-dropdown-header,
  .ng-dropdown-footer {
    border-color: var(--color-input-border);
  }

  .ng-dropdown-panel-items {
    .ng-option {
      color: var(--color-input-list-txt);
      background-color: var(--color-bg-input-list);
      padding: $spacing-3;
      font-weight: 400;
      border-radius: $border-radius-5;

      &.ng-option-marked {
        color: var(--color-input-list-txt-hover);
        background-color: var(--color-bg-input-list-selected);
        font-weight: 400;
      }

      &.ng-option-selected {
        color: var(--color-input-list-txt);
        background-color: var(--color-bg-input-list-selected);
        font-weight: 400;

        &.ng-option-marked {
          color: var(--color-input-list-txt);
          background-color: var(--color-bg-input-list-selected);
          font-weight: 400;

          &.ng-option-label {
            font-weight: 400;
          }
        }
      }

      &:last-child {
        border-radius: $border-radius-3;
      }
    }
  }

  &.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-radius: $border-radius-3;
  }

  &.ng-select-bottom,
  &.ng-select-top,
  &.ng-select-right,
  &.ng-select-left {
    border-radius: $border-radius-3;
    border-color: var(--color-input-border);
    margin: 0;
    overflow: hidden;
    padding: $spacing-1;
  }

  &.ng-select-bottom {
    transform: translateY(4px);
  }

  &.ng-select-top {
    transform: translateY(-4px);
  }

  &.ng-select-left {
    transform: translateX(-4px);
  }

  &.ng-select-right {
    transform: translateX(4px);
  }
}
