$border-radius-0: rem(2px);
$border-radius-1: rem(4px);
$border-radius-2: rem(8px);
$border-radius-3: rem(16px);
$border-radius-4: rem(24px);
$border-radius-5: rem(32px);
$border-radius-6: rem(40px);
$border-radius-7: rem(48px);
$border-radius-20: rem(20px);
$border-radius-100: rem(100px);
