// -------------------------------------
// Breakpoints
// -------------------------------------
$breakpoints: (
  xs: 320px,
  sm: 375px,
  md: 768px,
  xl: 1366px,
  2xl: 1920px,
);

// -------------------------------------
// @mixin: breakpoint($size)
// -------------------------------------
// usage:
// @include breakpoint(md or 450px) {
// - styles here --
// }
// -------------------------------------
@mixin breakpoint($size) {
  @if map-has-key($breakpoints, $size) {
    @media (min-width: map-get($breakpoints, $size)) {
      @content;
    }
  } @else {
    @media (min-width: $size) {
      @content;
    }
  }
}

// -------------------------------------
// @mixin: breakpoint-max($size)
// -------------------------------------
// usage:
// @include breakpoint-max(md or 450px) {
// - styles here --
// }
// -------------------------------------
@mixin breakpoint-max($size) {
  @if map-has-key($breakpoints, $size) {
    // The maximum value is reduced by 0.02px to work around the limitations of
    // `min-` and `max-` prefixes and with fractional viewport sizes.
    // See: https://www.w3.org/TR/mediaqueries-4/#mq-min-max
    // Use 0.02px rather than 0.01px to work around a current rounding bug in Safari.
    // See https://bugs.webkit.org/show_bug.cgi?id=178261
    $breakpoint-max-range-precision: 0.02px;

    @media (max-width: map-get($breakpoints, $size) - $breakpoint-max-range-precision) {
      @content;
    }
  } @else {
    @media (max-width: $size) {
      @content;
    }
  }
}

@mixin section-gap {
  padding: 0 $spacing-5;

  @include breakpoint(xl) {
    padding: 0 $spacing-10;
  }
}

@mixin mobile-scrollable-section-gap {
  padding-left: $spacing-5;

  @include breakpoint(xl) {
    padding: 0 $spacing-10;
  }
}

$z-indexes: (
  modal: 900,
  overlay: 800,
  dropdown: 700,
  sidebar: 650,
  sidebar-overlay: 649,
  header: 600,
  under-header: 599,
  footer: 500,
  hidden: -1,
  floating: 1000,
);

// -------------------------------------
// @function: z-index($layer)
// -------------------------------------
// usage:
// .modal {
//  z-index: z-index(modal);
// }
// -------------------------------------
@function z-index($layer) {
  @if not map-has-key($z-indexes, $layer) {
    @error 'No layer found for `#{$layer}` in $z-indexes map. Property omitted.';
  }

  @return map-get($z-indexes, $layer);
}
