@import "utilities";

// uc browser hide cdk overlays as its ads :D
div.cdk-overlay-container > div.cdk-global-overlay-wrapper > div[id*="cdk-overlay-"].cdk-overlay-pane {
  display: flex !important;
}

// Overlay backdrop styling
.cdk-overlay-backdrop.cabinet-home-overlay-backdrop {
  background-color: var(--color-pop-up-overlay);

  &.shadow {
    background-color: rgba(#000, 0.8);
    z-index: z-index(floating) + 1;
  }
}

.cabinet-home-overlay-panel {
  position: fixed !important;
  top: 0;
  right: 0;
  width: 70%;
  height: 100%;
  background-color: var(--color-sidebar-bg);
}

// Dialog
.cdk-overlay-backdrop.cabinet-home-dialog-overlay-backdrop {
  backdrop-filter: blur(18px);
}

.cabinet-home-dialog-overlay-panel {
  display: flex;
  flex-direction: column;
  cursor: auto;
  height: 100%;
  justify-content: center;
}
