@import "utilities";

@mixin active-label {
  @include font-regular-12;

  color: var(--color-input-txt-label);
  top: -1px;
  left: $spacing-2;
  padding: 0 $spacing-1;

  @include breakpoint(md) {
    @include font-regular-14;
  }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-clip: content-box !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  appearance: textfield; /* Standard */
}

.cabinet-control {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;

  // @include input-autofill-rewrite; // TODO: do we need it?

  &[disabled] {
    opacity: 0.4;

    .cabinet-control__icon {
      cursor: initial;
      pointer-events: none;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__input {
    @include font-regular-14;

    padding: $spacing-2 $spacing-3;
    border: 1px solid var(--color-input-border);
    border-radius: $border-radius-100;
    color: var(--color-input-txt-filled);
    background: transparent;
    margin-top: $input-margin-top;
    width: 100%;
    height: 40px;
    font-family: inherit;

    &::placeholder {
      color: var(--color-input-txt-placeholder);
      font-weight: 400;
    }

    &:focus {
      outline: none;

      + .cabinet-control__label {
        @include active-label;

        &.cabinet-control__label_small {
          @include font-regular-12;
        }
      }
    }

    &:read-only {
      pointer-events: none;
    }

    &_invalid:focus + .cabinet-control__label,
    &_invalid.cabinet-control__input:read-only + .cabinet-control__label {
      color: var(--color-state-danger);
    }

    &_invalid {
      border-color: var(--color-state-danger);
    }

    &_padding {
      padding-right: 42px;
    }
  }

  &__label {
    display: none;
  }

  &__icon {
    position: absolute;
    right: $spacing-3;
    top: calc(50% + 3px);
    transform: translateY(-50%);
    color: var(--color-input-txt-placeholder);

    &_tooltip {
      cursor: pointer;
    }
  }
}
