// -------------------------------------
// Custom variables
// -------------------------------------

// Header
$header-height: 56px;

// Sidebar
$sidebar-width: 70%;

// Accordion
$accordion-before-border: 2px solid;

// Strong-product
$strong-product-card-width: 430px;

// Career
$career-step-width: 270px;
$career-ad-width: 2500px;

// Providers
$provider-card-height: 170px;
$provider-card-min-width: 220px;

// Scrollbar
$scrollbar-vertical-width: 6px;

// Forms
$input-margin-top: 6px;

// Select
$select-height: 40px;
$select-min-width: 171px;

// Reviews
$reviews-card-width: 900px;
$reviews-mobile-card-width: 340px;
$reviews-card-image-height: 180px;
$reviews-card-image-width: 180px;
$reviews-mobile-card-image-height: 130px;
$reviews-mobile-card-image-width: 130px;
