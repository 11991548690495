@import "@angular/cdk/overlay-prebuilt.css";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "global";
@import "theme";

body {
  font-family: $font-family-montserrat;
  font-size: $base-font-size;
  color: var(--color-body);
  background: var(--color-bg-body);
  position: relative;

  &.theme {
    @include theme;
  }

  a {
    color: var(--color-font-1);
    text-decoration: none;
  }

  .text-highlighted {
    color: var(--color-highlighted);
  }

  .text-with-border {
    color: var(--color-start-text-with-border);
    text-shadow: 0 0 4px var(--color-start-text-border);
  }
}
