@import "utilities";

@mixin size-2xs {
  height: 12px;
  width: 12px;
}

@mixin size-xs {
  height: 16px;
  width: 16px;
}

@mixin size-s {
  height: 20px;
  width: 20px;
}

@mixin size-m {
  height: 24px;
  width: 24px;
}

@mixin size-l {
  height: 32px;
  width: 32px;
}

@mixin size-xl {
  height: 40px;
  width: 40px;
}

@mixin size-2xl {
  height: 48px;
  width: 48px;
}

@mixin size-full {
  height: 100%;
  width: 100%;
}

.cabinet-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  &-svg {
    // \3 escaping for numbers
    &.\32xs {
      @include size-2xs;
    }

    &.xs {
      @include size-xs;
    }

    &.s {
      @include size-s;
    }

    &.m {
      @include size-m;
    }

    &.l {
      @include size-l;
    }

    &.xl {
      @include size-xl;
    }

    &.\32xl {
      @include size-2xl;
    }

    &.full {
      @include size-full;
    }
  }
}

// -------------------------------------
// Special icons
// -------------------------------------
