@import "utilities";

.iti {
  width: 100%;
}

.iti > input {
  width: 100%;
  height: 40px;
  outline: none;
  background: transparent;
  border: 1px solid var(--color-input-border);
  border-radius: $border-radius-100;
  color: var(--color-input-txt-filled);

  @include font-regular-14;
}

.iti > input::placeholder {
  color: var(--color-input-txt-placeholder);

  @include font-regular-14;
}

.selected-dial-code {
  color: var(--color-input-txt-placeholder);

  @include font-regular-14;
}

.iti__selected-flag dropdown-toggle {
  color: var(--color-input-txt-filled);
}

.dropdown-menu.country-dropdown {
  background: var(--color-bg-list) !important;
  border-radius: $border-radius-20;
}

.iti__country-list {
  background: var(--color-bg-list) !important;
  border-radius: $border-radius-20;
  color: var(--color-input-txt-filled);
}

ngx-intl-tel-input.ng-invalid.ng-touched {
  input {
    border: 1px solid var(--color-state-danger);
  }
}
